// The key used in the storage center
const accessTokenKey = 'access_token'

export const AUTH_HOST = process.env.VUE_APP_SSO_HOST
export const AUTH_CLIENT_ID = process.env.VUE_APP_SSO_CLIENT_ID
export const VUE_FRONT_APP_HOST = process.env.VUE_APP_APP_FRONT

/**
 * Store the Token into the storage center
 *
 * @param {String} token - the token of the user
 */
export function storeAccessToken (token) {
  localStorage.setItem(accessTokenKey, token)
}

/**
 * Fetch and return the Access token from the storage center
 *
 * @return {String} the Access token
 */
export function getAccessToken () {
  return localStorage.getItem(accessTokenKey)
}

/**
 * Clear the Access Token from the storage center
 */
export function clearAccessToken () {
  localStorage.removeItem(accessTokenKey)
}

/**
 * Check if the Access Token is present in the storage center
 *
 * @return {bool} true if the Access Token is present, false if not
 */
export function isAccessTokenPresent () {
  return localStorage.getItem(accessTokenKey) !== null
}

/**
 * Check if the user is authenticated
 *
 * @return {bool} true if the user is authenticated, false if not
 */
export function isAuthenticated () {
  return localStorage.getItem(accessTokenKey) !== null
}