<template>
  <header class="pdpHeader">
    <div class="nav-left">
      <router-link v-if="($route.name === 'PressProduct')" :to="{ name: 'PressCollection'}">
        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.52 14.6667L0 7.33333L7.52 0L8 0.473333L0.966667 7.33333L8 14.1933L7.52 14.6667Z" fill="#767676"/>
        </svg>
      </router-link>
    </div>
    <div class="nav-center">
        <router-link to="/">
        </router-link>
    </div>
    <div class="nav-right-legend">
      <div class="nav-right">
    </div>
    </div>
  </header>
</template>
<script>
export default {
  methods: {
  },
};
</script>