<template>
    <header>
    <div class="nav-left">
    </div>
    <div class="nav-center">
        <router-link to="/">
          <svg id="logo" viewBox="0 0 175 28" width="100%" height="100%"><path d="M20.655 17.726l4.565 2.615c-2.282 4.197-6.737 6.922-11.781 6.922C6.075 27.263 0 21.629 0 13.713S6.075.163 13.439.163c5.044 0 9.5 2.725 11.781 6.923L20.655 9.7c-1.326-2.725-4.013-4.381-7.216-4.381-4.603 0-8.1 3.423-8.1 8.394s3.497 8.395 8.1 8.395c3.203 0 5.89-1.657 7.216-4.382m29.05 8.874V15.554H36.818V26.6h-5.154V.826h5.154V10.4h12.887V.826h5.155V26.6h-5.155m29.898-10.678L74.926 5.061 70.25 15.922h9.353zM89.838 26.6h-5.634l-2.54-5.892H68.188l-2.54 5.892h-5.634L71.428.826h6.996L89.838 26.6zm23.748 0L99.778 6.313V26.6h-4.786V.826h6.812l11.598 17.084V.826h4.787V26.6h-4.603m14.543 0V.826h18.41v4.787h-13.624v5.523h11.782v4.786h-11.782v5.892h14.36V26.6h-19.146m27.431 0V.826h5.154v20.62h13.622V26.6H155.56"></path></svg>
        </router-link>
    </div>
    <div class="nav-right">
    </div>
  </header>
  <div class="mainContainer">
    <div class="preHomeContainer">
      <div class="preHomeSelector">
        <div class="title">Please select your language</div>

        <div class="marketList">
          <router-link class="title" :to="{ name: 'Press', params: {lang: 'en'}}">
              ENGLISH
          </router-link>
          <div class="title" style="padding-inline: 20px"> | </div>
          <router-link class="title" :to="{ name: 'Press', params: {lang: 'fr'}}">
              FRANÇAIS
          </router-link>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
      return {
      };
  },
  created: function() {
        this.scrollToTop()
  },
  methods:{
    scrollToTop() {
        window.scrollTo(0,0);
    }
  }
}
</script>
