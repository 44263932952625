<template>
  <header>
    <div class="nav-left" v-if="$route.name == 'PressCategories'">
      <router-link :to="{ name: 'Press', params: { lang: this.$route.params.lang } }">
        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.52 14.6667L0 7.33333L7.52 0L8 0.473333L0.966667 7.33333L8 14.1933L7.52 14.6667Z" fill="#1D1D1D" />
        </svg>
      </router-link>
    </div>
    <div class="nav-left" v-if="$route.name == 'PressCollection' || $route.name == 'CapsuleCollection'">
      <router-link :to="{ name: 'PressCategories' }">
        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.52 14.6667L0 7.33333L7.52 0L8 0.473333L0.966667 7.33333L8 14.1933L7.52 14.6667Z" fill="#1D1D1D" />
        </svg>
      </router-link>
    </div>
    <div class="nav-center-press">
      <img :src="require(`@/assets/images/logoSport.svg`)" />
    </div>
    <div class="nav-right">
      <a @click="navigateToAtrium" v-if="$route.name !== 'Press' && $route.name !== 'PressCategories'">
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="11.8571" cy="11.5" r="9.35714" stroke="#767676" />
          <path d="M10.3575 9.43222L7.0368 15.6981L13.4986 13.1756L16.7902 7.51404L10.3575 9.43222Z" fill="#767676" />
          <circle cx="11.8571" cy="11.4999" r="0.5" stroke="white" stroke-width="0.642857" />
        </svg>
      </a>
    </div>
  </header>
</template>

<script>
import dataJSON from '@/assets/press.json';

export default {
  inheritAttrs: false,
  data() {
    return {
      lang: null,
      singleCat: null
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      const versions = dataJSON.versions;
      this.lang = versions.find(version => version.lang === this.$route.params.lang);
      this.singleCat = this.lang.categories.find(category => category.slug === this.$route.params.categories);
    },
    navigateToAtrium() {
      this.$router.push({ name: 'PressCategories', params: { lang: this.$route.params.lang, categories: 'scenography' } });
    }
  }
};
</script>
