import { createRouter, createWebHistory } from 'vue-router'
import { isAuthenticated } from '@/utils/authentication'
import PageNotFound from '@/views/PageNotFound.vue'
import LoginCallback from '@/components/tools/LoginCallback'
import Login from '@/components/tools/Login'
import PreHome from '../views/PreHome.vue'
import Press from '@/views/Home.vue'
import PressCategories from '@/views/Category.vue'
import PressCollection from '@/views/Collection.vue'
import PressProduct from '@/views/SingleProduct.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/callback',
    name: 'redirect_uri',
    component: LoginCallback,
  },
  {
    path: '/',
    redirect: { name: 'Press', params:{lang: 'en'} },
  },
  {
    path: '/haute-joaillerie-sport/',
    name: 'PreHome',
    component: PreHome,
    props: true,
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/haute-joaillerie-sport/:lang',
    name: 'Press',
    component: Press,
    props: true,
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/haute-joaillerie-sport/:lang/:categories',
    name: 'PressCategories',
    component: PressCategories,
    props: true,
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/haute-joaillerie-sport/:lang/:categories/:chapterName',
    name: 'PressCollection',
    component: PressCollection,
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/haute-joaillerie-sport/:lang/:categories/:chapterName/:productRef',
    name: 'PressProduct',
    component: PressProduct,
    meta: {
     requiresAuth: true
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: 'PageNotFound',
    component: PageNotFound
  }
]

const router = createRouter({
  inheritAttrs: true,
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const securedRoute = to.matched.some(record => record.meta.requiresAuth)
  if (securedRoute && !isAuthenticated()) {
    next({
      name: 'login',
      query: {
        redirect: `${to.fullPath}`
      }
    })
  } else {
    next()
  }
})


export default router
