<template>
  <div>
    <topNav />
    <div class="product">
      <div class="singleProductImage">
        <div class="productImageContainer">
          <div class="slider">
            <vueper-slides class="no-shadow" :arrows="true" :fixed-height="false">
              <vueper-slide v-for="(slide, index) in singleProduct.sliderList" :key="index" :image="getImage(slide.img)">
                <template v-slot:content>
                  <div :class="slide.blackLegend" class="sliderLegend">{{ slide.legend }}</div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
      </div>
      <div class="productInfos">
        <div class="singleProductTitle" v-html="singleProduct.name"></div>
        <div class="singleProductType">{{ singleProduct.type }}</div>
        <div class="productRef" v-if="singleProduct.displayedRef === null">
          {{ singleProduct.ref }}<br/>
          <span v-if="singleProduct.notAvailable !== true">{{ lang.price }}: {{ singleProduct.price }}</span>
          <span v-if="singleProduct.notAvailable === true"><svg style="padding-right: 5px;" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#BA0000"><path d="M352-172 172-352v-256l180-180h256l180 180v256L608-172H352Zm14-174 114-114 114 114 20-20-114-114 114-114-20-20-114 114-114-114-20 20 114 114-114 114 20 20Zm-2 146h232l164-164v-232L596-760H364L200-596v232l164 164Zm116-280Z"/></svg> {{ lang.notAvailable }}</span>
        </div>
        <div class="productRef" v-else>
          {{ singleProduct.displayedRef }}<br/>
          <span v-if="singleProduct.notAvailable !== true">{{ lang.price }}: {{ singleProduct.price }}</span> 
          <span v-if="singleProduct.notAvailable === true"><svg style="padding-right: 5px;" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#BA0000"><path d="M352-172 172-352v-256l180-180h256l180 180v256L608-172H352Zm14-174 114-114 114 114 20-20-114-114 114-114-20-20-114 114-114-114-20 20 114 114-114 114 20 20Zm-2 146h232l164-164v-232L596-760H364L200-596v232l164 164Zm116-280Z"/></svg> {{ lang.notAvailable }}</span>
        </div>
        <div class="singleProductDesc">
          <div class="productSubDesc">
            <div v-for="(attribute, index) in filteredProductAttributes" :key="index" class="productAttribute">
              <span>{{ lang[attribute] }}</span><br>{{ singleProduct[attribute] }}
            </div>
            <div class="productLegals">
              {{ lang.mentions }}
            </div>
            <div class="productAttribute" v-html="singleProduct.hashtags"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from '@/components/vueperslides.common.js';
import topNav from '@/components/pdpTopNav.vue';

export default {
  components: {
    topNav,
    VueperSlides,
    VueperSlide
  },
  data() {
    return {
      singleProduct: {},
      lang: {}
    };
  },
  created() {
    this.initializeData();
    this.scrollToTop();
  },
  methods: {
    async initializeData() {
      const { lang, productRef } = this.$route.params;
      this.lang = (await import(`@/assets/press.json`)).default.versions.find(v => v.lang === lang);
      this.singleProduct = (await import(`@/assets/products/${lang}/${productRef}.json`)).default;
    },
    getImage(img) {
      return require(`@/assets/images/products/${img}`);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },
  computed: {
    filteredProductAttributes() {
      return this.productAttributes.filter(attribute => this.singleProduct[attribute]);
    },
    productAttributes() {
      return [
        'description', 'stones', 'transformable', 'reproduction'
      ];
    }
  }
};
</script>

<style scoped>
/* Add necessary styles here */
</style>
