<template>
  <topNav />
  <div class="mainContainer" v-if="singleChapter">
    <div v-if="singleCat.isNovelties === true" class="collectionCover">
      <img
        :class="singleChapter.imgPosition"
        rel="preload"
        :src="chapterImage"
      />
    </div>
    <div v-else class="scenographyDisplayTitle">
      <span>{{ singleChapter.name }}</span><br/>
      {{ this.lang.display }} {{ singleChapter.number }}
    </div>
    <div class="pressProducts">
      <div class="pressSubProducts" v-for="(group, index) in singleChapter.productGroups" :key="index">
        <div class="SubProductsTitle" v-if="group.title !== null">{{ group.title }}</div>
        <div :class="['productGroup', { 'no-gap': group.products.length === 1 }]" :ref="'productGroup' + index">
          <router-link
            class="productTile"
            v-for="product in group.products"
            :key="product.ref"
            :to="{ name: 'PressProduct', params: { productRef: product.ref } }"
          >
            <div class="productImage">
              <img
                rel="preload"
                :src="getProductImage(product.ref)"
              />
            </div>
            <div class="productTitle" style="display: flex; align-items: center; justify-content: center;">
              <div v-html="product.name"></div>
              <div v-if="product.notAvailable === true" style="display: flex;margin-left: 5px;">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#BA0000">
                  <path d="M352-172 172-352v-256l180-180h256l180 180v256L608-172H352Zm14-174 114-114 114 114 20-20-114-114 114-114-20-20-114 114-114-114-20 20 114 114-114 114 20 20Zm-2 146h232l164-164v-232L596-760H364L200-596v232l164 164Zm116-280Z"/>
                </svg>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>

<script>
import dataJSON from "@/assets/press.json";
import topNav from "@/components/topNav.vue";

export default {
  inheritAttrs: false,
  components: {
    topNav,
  },
  data() {
    return {
      lang: null,
      singleCat: null,
      singleChapter: null,
    };
  },
  computed: {
    chapterImage() {
      return this.singleChapter ? require(`@/assets/images/chapters/${this.singleChapter.slug}.png`) : '';
    },
    chapterNumberImage() {
      return this.singleChapter ? require(`@/assets/images/numbers/${this.singleChapter.id}.png`) : '';
    }
  },
  created() {
    this.getDetails();
    this.scrollToTop();
  },
  methods: {
    async getDetails() {
      try {
        const versions = dataJSON.versions;
        this.lang = versions.find((version) => version.lang === this.$route.params.lang);
        if (!this.lang) {
          throw new Error('Language not found');
        }
        this.singleCat = this.lang.categories.find(
          (category) => category.slug === this.$route.params.categories
        );
        if (!this.singleCat) {
          throw new Error('Category not found');
        }
        this.singleChapter = this.singleCat.chapters.find(
          (chapter) => chapter.slug === this.$route.params.chapterName
        );
        if (!this.singleChapter) {
          throw new Error('Chapter not found');
        }

        await Promise.all(
          this.singleChapter.productGroups.map(async (group) => {
            await Promise.all(
              group.products.map(async (product) => {
                try {
                  const productData = await import(
                    `@/assets/products/${this.lang.lang}/${product.ref}.json`
                  );
                  Object.assign(product, productData.default);
                } catch (error) {
                  console.error(`Failed to load product data for ref: ${product.ref}`, error);
                }
              })
            );
          })
        );
      } catch (error) {
        console.error('Failed to load details:', error);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getProductImage(ref) {
      try {
        return require(`@/assets/images/products/${ref}.jpg`);
      } catch (error) {
        console.error(`Failed to load image for ref: ${ref}`, error);
        return ''; // Return an empty string or a placeholder image
      }
    }
  }
};
</script>
