<template>
  <topNav/>
  <div class="mainContainer">
    <div class="pressChaptersContainer">
      <router-link v-for="category in this.lang.categories" :key="category.id" class="chapterTile" :to="{ name: 'PressCategories', params: {categories: category.slug}}">
          <div class="chapterTitle">
            <div v-html="category.name"></div>
          </div>
          <img :src="require(`@/assets/images/divisions/${category.img}.jpg`)"/>
      </router-link>
      </div>
    </div>
</template>

<script>

import topNav from '@/components/topNav.vue'
import dataJSON from '@/assets/press.json'

export default {
  components: {
    topNav,
  },
  data() {
      return {
      };
  },
  created: function() {
        this.getDetails()
        this.scrollToTop()
  },
  methods:{
      getDetails(){
      const versions = dataJSON.versions
      this.lang = Array.from(versions).find(version => version.lang === this.$route.params.lang);
    },
    scrollToTop() {
        window.scrollTo(0,0);
    }
  }
}
</script>
