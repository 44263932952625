<template>
  <div>
    <topNav />

    <div v-if="singleCat.atrium">
      <div class="listContainer">
        <div class="listOfButtons">
          <button 
            v-for="(button, index) in buttons" 
            :key="index" 
            @click="loadMap(index)" 
            :disabled="button.disabled"
            :class="{ active: button.disabled }"
          >
            {{ button.label }}
          </button>
        </div>
      </div>

      <div class="map-wrapper" ref="mapWrapper">
        <div class="map-container" ref="mapContainer">
          <img
            class="map-background"
            :src="backgroundImage"
            @load="onImageLoad"
          />
          <div
            v-for="pin in pins"
            :key="pin.id"
            :style="getPinStyle(pin)"
            class="pin"
            @click="navigate(pin.link)"
          >
            <img :src="getPinImage(pin.image)" class="pin-image" />
          </div>
        </div>
      </div>

    </div>
    
    <div v-if="singleCat.isNovelties" class="mainContainer">
      <div class="collectionIntroductionContainer">
        <router-link 
          class="chapterTile" 
          v-for="chapter in chaptersContent" 
          :key="chapter.id" 
          :to="{ name: chapter.route, params: { chapterName: chapter.slug, chapterID: chapter.id }}"
        >
          <img :class="chapter.imgPosition" :src="getChapterImage(chapter.slug)" />
        </router-link>
      </div>
      
      <div class="quoteIntroduction">
        <div class="quoteText" v-html="lang.introduction"></div>
        <div class="quoteAuthor">
          {{ lang.author }}
          <br />
          <span>{{ lang.authorTitle }}</span>
        </div>
      </div>
      
      <div class="mainIntroduction">
        <div class="textBlock">
          <div class="textBlockTitle" v-html="lang.collectionTitle"></div>
          <div class="textBlockBody" v-html="lang.collectionBody"></div>
          <ul class="imagesLegends">
            <li v-for="legend in lang.legends" :key="legend.id">{{ legend.legend }}</li>
          </ul>
        </div>
        <div class="imagesBlock">
          <img class="firstImage" :src="getCollectionImage(lang.img1)" />
          <img class="secondImage" :src="getCollectionImage(lang.img2)" />
          <img class="thirdImage" :src="getCollectionImage(lang.img3)" />
        </div>
      </div>
      
      <div class="mainIntroduction">
        <div class="textBlock">
          <div class="textBlockTitle" v-html="lang.secondaryIntroductionTitle"></div>
          <div class="textBlockBody" v-html="lang.secondaryIntroductionBody"></div>
        </div>
        <div class="textImageBlock">
          <div class="textBlockTitle" v-html="lang.aestheticTitle"></div>
          <img class="blockImage" :src="getCollectionImage(lang.aestheticImg)" />
          <div class="textBlockBody" v-html="lang.aestheticBody"></div>
        </div>
      </div>

      <div class="mainIntroduction">
        <div class="textImageBlock">
          <div class="textBlockTitle" v-html="lang.gemstonesTitle"></div>
          <img class="blockImage" :src="getCollectionImage(lang.gemstonesImg)" />
          <div class="textBlockBody" v-html="lang.gemstonesBody"></div>
        </div>
        <div class="textImageBlock">
          <div class="textBlockTitle" v-html="lang.technicTitle"></div>
          <img class="blockImage" :src="getCollectionImage(lang.technicImg)" />
          <div class="textBlockBody" v-html="lang.technicBody"></div>
        </div>
      </div>
    </div>

    <div v-if="singleCat.isTimeline" class="mainContainer" style="display: flex; align-items: center; overflow-x: auto;">
      <ul class="timeline">
        <li class="date" v-for="date in this.singleCat.dates" :key="date.id">
          <div class="timeFrame">
            <div class="dot"></div>
            <div class="line"></div>
          </div>
          <div class="year">{{date.year}}</div>
          <div class="keyInfo" v-html="date.keyDate"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import topNav from '@/components/topNav.vue';
import dataJSON from '@/assets/press.json';
import mapDataJSON from "@/assets/scenography.json";

export default {
  components: {
    topNav,
  },
  data() {
    return {
      showFullText: false,
      chaptersContent: [],
      buttons: [
        { label: 'HALL OF CHAPTERS', disabled: false },
        { label: 'TRAINING', disabled: false },
        { label: 'STADIUM', disabled: false }
      ],
      activeButtonIndex: 0, // new state to keep track of active button
      mapData: [],
      backgroundImage: '',
      pins: [],
      imageWidth: 1680,
      imageHeight: 1080,
      startPosition: {
        vertical: 'top',
        horizontal: 'left'
      }
    }
  },
  created() {
    this.mapData = mapDataJSON.mapData.map(map => ({
      ...map,
      backgroundImage: require(`@/assets/images/maps/${map.backgroundImage.split('/').pop()}`)
    }));
    const storedIndex = localStorage.getItem('activeButtonIndex');
    const indexToLoad = storedIndex !== null ? parseInt(storedIndex) : 0;
    this.loadMap(indexToLoad); // load map based on stored active button index
    this.getDetails();
    this.scrollToTop();
  },
  methods: {
    getPinStyle(pin) {
      return {
        position: "absolute",
        top: `${pin.y * this.imageHeight}px`,
        left: `${pin.x * this.imageWidth}px`,
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
      };
    },
    navigate(link) {
      this.$router.push(link);
    },
    onImageLoad() {
      this.setInitialScrollPosition();
    },
    setInitialScrollPosition() {
      const container = this.$refs.mapWrapper;
      const { vertical, horizontal } = this.startPosition;

      switch (vertical) {
        case 'top':
          container.scrollTop = 0;
          break;
        case 'bottom':
          container.scrollTop = container.scrollHeight - container.clientHeight;
          break;
        case 'center':
          container.scrollTop = (container.scrollHeight - container.clientHeight) / 2;
          break;
        default:
          break;
      }

      switch (horizontal) {
        case 'left':
          container.scrollLeft = 0;
          break;
        case 'right':
          container.scrollLeft = container.scrollWidth - container.clientWidth;
          break;
        case 'center':
          container.scrollLeft = (container.scrollWidth - container.clientWidth) / 2;
          break;
        default:
          break;
      }
    },
    loadMap(index) {
      const map = this.mapData[index];
      this.backgroundImage = map.backgroundImage;
      this.pins = map.pins;
      this.startPosition = map.startPosition;

      this.buttons.forEach((button, i) => {
        button.disabled = i === index;
      });

      this.activeButtonIndex = index; // store active button index
      localStorage.setItem('activeButtonIndex', index); // persist in localStorage

      this.$nextTick(() => {
        this.setInitialScrollPosition();
      });
    },
    getPinImage(image) {
      return require(`@/assets/images/highlights/${image}`);
    },
    getChapterImage(slug) {
      return require(`@/assets/images/chapters/${slug}.svg`);
    },
    getCollectionImage(image) {
      return require(`@/assets/images/collections/${image}.jpg`);
    },
    getDetails() {
      const versions = dataJSON.versions;
      this.lang = versions.find(version => version.lang === this.$route.params.lang);
      this.singleCat = this.lang.categories.find(category => category.slug === this.$route.params.categories);
      if (this.singleCat.slug !== "timeline") {
        this.chaptersContent = this.singleCat.chapters.filter(chapter => chapter.id);
      } else {
        this.timelineContent = this.singleCat.dates.filter(date => date.id);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped>
.map-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.map-container {
  position: relative;
  width: 1668px;
  padding-bottom: 10px;
}

.map-background {
  display: block;
  width: 100%;
  height: 100%;
}

.pin {
  font-size: 24px;
}
</style>
