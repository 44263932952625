<template>
  <topNav/>
  <div class="mainContainer">
    404
  </div>
</template>

<script>

import topNav from '@/components/topNav.vue'

export default {
  name: 'not-found',
  components: {
    topNav,
  }
}
</script>
