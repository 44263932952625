<template>
    <router-view :key="$route.fullPath" />
</template>

<script>

export default {
}
</script>


<style lang="scss">
@import './assets/sass/styles.scss';
</style>
