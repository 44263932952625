<script>
import { isAuthenticated, AUTH_HOST, AUTH_CLIENT_ID, VUE_FRONT_APP_HOST } from '@/utils/authentication'

export default {
  name: 'login',
  created() {
    if (isAuthenticated()) {
      const redirectPath = this.$route.query.redirect || '/'
      this.$router.push(redirectPath)
    } else {
      // Validate the client_id and response_type values
      if (AUTH_CLIENT_ID !== process.env.VUE_APP_SSO_CLIENT_ID || 'token' !== 'token') {
        throw new Error('Invalid client_id or response_type')
      }

      // Validate the redirect URI
      let redirectUri = window.location.origin + '/callback'
      console.log(redirectUri)
      if (redirectUri !== VUE_FRONT_APP_HOST + '/callback') {
        throw new Error('Invalid redirect URI')
      }

      let redirectPath = this.$route.path !== '/' ? this.$route.path : '/'
      if (this.$route.query.redirect && typeof this.$route.query.redirect === 'string') {
        redirectPath = this.$route.query.redirect
      } else if (this.$route.query.redirect && typeof this.$route.query.redirect === 'object' && this.$route.query.redirect.name) {
        redirectPath = this.$router.resolve(this.$route.query.redirect).href
      }
      
      location.href = AUTH_HOST + '/authorize?' +
        'client_id=' + AUTH_CLIENT_ID +
        '&response_type=token' +
        '&redirect_uri=' + encodeURIComponent(redirectUri + '?redirect=' + encodeURIComponent(redirectPath))
    }
  }
}
</script>