<script>
import { storeAccessToken } from '@/utils/authentication'

export default {
  name: 'login_callback',
  data () {
    return {
      errr: false
    }
  },
  mounted () {
    let fullHash = this.$route.hash.replace('#','');
    let hashArray = fullHash.split('&');
    let code = "";
    hashArray.forEach(hashItem => {
      if(hashItem.includes("access_token=")){
        code = hashItem.replace('access_token=','');
      }
    })
    if (code !== undefined && code !== null && code !== "") {
      this.finishLogin(code)
    } else {
      this.errr = true
    }
  },
  methods: {
    finishLogin: function (code) {
      if (code) {
        storeAccessToken(code)
        const redirectPath = this.$route.query.redirect
        if (redirectPath && typeof redirectPath === 'string') {
          this.$router.push(redirectPath)
        } else if (redirectPath && typeof redirectPath === 'object' && redirectPath.name) {
          this.$router.push(redirectPath)
        } else {
          this.$router.push('/')
        }
      } else {
        this.errr = true
      }
    }
  }
}
</script>